<style scoped>
.v-text-field.v-text-field--solo .v-input__control {min-height: 40px !important;padding: 0;}
.v-btn:not(.v-btn--round).v-size--default {height: 40px;min-width: 64px;padding: 0 16px;}
</style>


<template>
<div>
  <v-flex class="pa-2">
    <v-container fluid>
      <v-layout row wrap align-start justify-start>
        <v-flex xs12 sm12 md12 lg12 xl12 class="pa-0">
          <v-card class="a-box">
            <v-row class="ma-0" width="fit-content">
              <v-col class="" cols="6" sm="2" xs="12">
                <div>
                 <v-select height="30" dense :items="items" v-model="instrument"  outlined hide-details label="Instrument Name" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                </div>
              </v-col>
               <v-col class="" cols="6" sm="2" xs="12">
              <div>
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field outlined dense disabled v-model="selectdate" prepend-inner-icon="mdi-calendar" v-bind="attrs" hide-details label="Select Date" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="selectdate" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.menu.save(selectdate)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-col>
               <v-col class="" cols="6" sm="2" xs="12">
                  <v-select v-model="expirydate" :items="expiryList" dense
                      outlined hide-details label="​Expiry Date" :menu-props="{ bottom: true, offsetY: true }"></v-select>
              </v-col>
               <v-col class="" cols="6" sm="2" xs="12">
                    <v-btn color="primary" dense>Go</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-flex>
         <v-flex xs12 class="pl-2 pr-2 pb-4">
              <v-container fluid class="chartfullview1">
                <v-layout row wrap align-start justify-start>
                  <v-flex  xs12 sm12 md12 lg12 xl12 class="pa-0">
                    <v-card class="a-box pa-2">
                    <!-- <v-chart class="chart" autoresize :option="option" theme="macarons" /> -->
                    </v-card>
                  </v-flex>
                </v-layout>
                </v-container>
          </v-flex>
</div>
</template>
<script>
// import VChart, { THEME_KEY } from 'vue-echarts'
// import '@/plugins/echart'
export default {
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: 'light'
  },
  data () {
    return {
      selectdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      menu: false,
      instrument: "Nifty",
      expirydate: "",
      expiryList: [],
      items: ["Banknifty", "Nifty", "SBI"],
   
       
      // option: {
      //   // title: {
      //   //   textStyle: {color: 'lightgrey',fontSize: 20},
      //   //   subtextStyle: {color: 'lightgrey',fontSize: 18,fontWeight: 400},
      //   //   text: 'Amoga.tech',
      //   //   subtext: 'Premium Left',
      //   //   left: 'center',
      //   //   top: 'center'
      //   // },
      //   theme:"macarons",
      //   tooltip: {trigger: 'axis'},
      //   legend: {data: ['Combined', 'VWAP'],bottom: '1%'},
      //   grid: {left: '3%',right: '4%',bottom: 60,containLabel: true},
      //   toolbox: {
      //     feature: {
      //       dataView: { show: true, readOnly: false },
      //       magicType: { show: true, type: ['line', 'bar'] },
      //       restore: { show: true },
      //       saveAsImage: { show: true }
      //     }
      //   },

      //   xAxis: {
      //   type: 'category',//axisPointer: {type: 'shadow',shadowColor: '#E6E5E4'},
      //   data: ['10.1', '11.1', '12.1', '13.4', '14.7', '15.9', '16.3', '17.3', '18.9', '19.3', '20.9']
      //   },
      //   yAxis: {type: 'value'},
      //   series: [
      //     {name: 'Combined',type: 'bar',smooth: true,
      //      data: [28.0, 64.9, 170.0, 53.2, 25.6, 76.7, 135.6, 12.2, 32.6, 20.0, 96.4],
      //       markLine: {data: [{ type: 'average', name: 'Marker Line', valueIndex: 0,}],}},
      //     {name: 'VWAP',type: 'bar',smooth: true, data: [10.6, 55.9, 39.0, 96.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 86.0],
      //     markLine: {data: [{ type: 'average', name: 'Marker Line', valueIndex: 0,  }],}}
      //   ]
      // }
    }
  },
  mounted() {
      var isDark = localStorage.getItem("dark_theme");
      var theme = (isDark == 'true')?'dark':'light';
      this.option.legend.textStyle = { "color": "#fff" } ;
      this.option = Object.assign(this.global.chart.defaultTheme[theme],this.option);
  },
}
</script>
